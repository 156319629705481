<template>
  <div class="home-title">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: ["title", "img"],
};
</script>

<style scoped>
.home-title {
  font-size: 18px;
  font-family: Avenir-Heavy, Avenir, sans-serif;
  font-weight: 800;
  color: #000000;
  line-height: 24px;
}
</style>
