<template>
  <div class="list">
    <div class="list-title">
      <Title :title="$t('accountlist.name')" />
    </div>
    <List
      :data="accounts.PLTHolderInfos"
      :handleCurrentChange="handleCurrentChange"
      :rowDatas="rowDatas"
      :total="accounts.TotalCount"
      :secondColumnWidth="400"
      :thirdColumnWidth="300"
      :addressColumn="1"
      :current-page="currentPage"
    />
  </div>
  <Graph />
</template>

<script>
import { getPltHolders } from "@/api/_common";
import Title from "@/components/SecondaryTitle.vue";
import List from "@/components/List.vue";
import Helper from "@/utils/helper";
import Graph from "./graph.vue";

export default {
  components: {
    Title,
    List,
    Graph,
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page);
    },
  },
  data() {
    return {
      accounts: [],
      total: 0,
      pageSize: this.$route.params.pageSize
        ? Number(this.$route.params.pageSize)
        : 10,
      rowDatas: [
        {
          prop: "Rank",
          label: "accountlist.rank",
          method: (row) => row.Rank,
        },
        {
          prop: "Address",
          label: "accountlist.address",
          method: (row) => row.Address,
        },
        {
          prop: "Amount",
          label: "accountlist.balance",
          method: (row) => Helper.toFinancialVal(row.Amount),
        },
        {
          prop: "Percent",
          label: "accountlist.percent",
          method: (row) => row.Percent,
        },
      ],
    };
  },
  watch: {
    $route: async function () {
      await this.getPltHolders();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
  async mounted() {
    if (!this.$route.query.page) {
      await this.$router.replace({
        query: {
          page: 1,
        },
      });
    }
    await this.getPltHolders();
  },
  methods: {
    async getPltHolders() {
      const res = await getPltHolders({
        PageNo: this.currentPage - 1,
        PageSize: this.pageSize,
      });
      this.accounts = res;
      for (let i = 0; i < this.pageSize; i++) {
        if (this.accounts && this.accounts.PLTHolderInfos[i]) {
          this.accounts.PLTHolderInfos[i].Rank =
            this.pageSize * (this.currentPage - 1) + i + 1;
        }
      }
    },
    async handleCurrentChange(newPage) {
      await this.$router.replace({
        query: {
          page: newPage,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/list.scss";
:deep(.list .list-content) {
  padding: 4vw 3vw;
}
:deep(.el-table td > .cell) {
  @media only screen and (max-width: 767px) {
    font-size: 3vw;
  }
}
</style>
